import { useRef, useState, useEffect } from "react";

import { chatApi, AskResponse, ChatRequest, ChatTurn } from "./api";
import { Answer, AnswerError, AnswerLoading } from "./components/Answer";
import { QuestionInput } from "./components/QuestionInput";
import { UserChatMessage } from "./components/UserChatMessage";
import { ClearChatButton } from "./components/ClearChatButton";
import { ExampleList } from "./components/Example";

import styles from "./Chat.module.css";
import logo from "./logo.png";

function App() {

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [selectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }]
            };
            const latestAnswer = answers[answers.length - 1]?.[1];
            const result = await chatApi(request, getQuestion(question, latestAnswer));
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const getHistoryAnswer = (askResponse: AskResponse) => {
        const model = removeAfterLastDash(askResponse.source_documents_pages[0]);
        return "Regarding model: " + model
                + "\n\n" + askResponse.answer;
    };

    const getQuestion = (question: string, latestAnswer: AskResponse) => {
        if(!latestAnswer) {
            return question;
        }

        const model = removeAfterLastDash(latestAnswer.source_documents_pages[0]);
        return "Regarding model: " + model
                + "\n\n" + question;
    };

    function removeAfterLastDash(input: string): string {
        const parts = input.split('-');
        
        // If no "-" found, return the original string
        if (parts.length === 1) {
            return input;
        }
        
        // Remove the last part after the last "-"
        parts.pop();
        return parts.join('-');
    }

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setAnswers([]);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };    

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
            </div>

            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2>
                            <ExampleList onExampleClicked={onExampleClicked} />                            
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index }
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading&& (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question (e.g. How do I avoid kickback on a chainsaw?)"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default App;