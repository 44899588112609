import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "How do I prepare my sandwich grill for use?",
        value: "How do I prepare my sandwich grill for use?"
    },
    { 
        text: "How do I avoid kickback when using my chainsaw?", 
        value: "How do I avoid kickback when using my chainsaw?" 
    },
    { 
        text: "How do I clean my iron?", 
        value: "How do I clean my iron?" 
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
