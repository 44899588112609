import { AskResponse,AskResponseContainer, ChatRequest } from "./models";

export async function chatApi(options: ChatRequest, question: string): Promise<AskResponse> {
    const response = await fetch("https://active-openai-demo-api.salmondesert-9ed62966.westeurope.azurecontainerapps.io/api/rag/searchqna", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
            question: question,
            history: options.history 
        })        
    });

    const parsedResponse: AskResponseContainer = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse.answer;
}
