import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse } from "../../api";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
}

export const Answer = ({
    answer,
    isSelected
}: Props) => {
    const parsedAnswer = answer.answer.trim();

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!answer.source_documents.length && (
                <Stack.Item>
                    <Stack wrap tokens={{ childrenGap: 5 }}>
                        <div className={styles.sourcesLink}>Sources:</div>
                        {answer.source_documents.map((x, i) => {
                            return (
                                <a href={x} key={i} className={styles.citation} target="_blank" >
                                    {`${++i}. ${answer.source_documents_pages[i-1]}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}                

        </Stack>
    );
};
